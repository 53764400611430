export default function validatePassword(password) {
  const overall = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*!@$%#]).{8,40}$/.test(password);

  const number = /^(?=.*[0-9])/.test(password);
  const lowercase = /^(?=.*[a-z])/.test(password);
  const uppercase = /^(?=.*[A-Z])/.test(password);
  const symbol = /^(?=.*[*!@$%#])/.test(password);
  const length = /^.{8,40}$/.test(password);

  return {
    overall,
    number,
    lowercase,
    uppercase,
    symbol,
    length,
  };
}
