import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import CheckIcon from '@material-ui/icons/Check';

import TokenLogo from '../assets/img/TokenLogo.svg';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '100%',
  },
  title: {
    textAlign: 'center',
  },
  headerImage: {
    maxWidth: 222,
  },
  content: {
    textAlign: 'center',
    '& > *': {
      margin: '16px 0',
    },
  },
  checkIcon: {
    fontSize: 200,
    color: 'green',
  },
}));

const SuccessDialog = ({ buttonLabel, children, onClose, open }) => {
  const classes = useStyles();
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setDuration((prevVal) => {
          if (prevVal === 99) {
            onClose();
            return 100;
          }
          return prevVal >= 100 ? 100 : prevVal + 1;
        });
      }, 80);

      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, onClose]);

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle className={classes.title}>
        <img src={TokenLogo} alt="Token Logo" className={classes.headerImage} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <CheckIcon className={classes.checkIcon} />
        {children}
        <LinearProgress variant="determinate" value={duration} style={{ width: '100%' }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
