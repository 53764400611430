import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { requestVerificationCode } from '../api/verification';

const PhoneVerification = ({ open, onClose, phone, submit }) => {
  const { t: getText } = useTranslation();

  const [phoneCode, setPhoneCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [requestStatus, setRequestStatus] = useState('idle');
  const [errorMsg, setErrorMsg] = useState('');

  const requestVerification = async (phone) => {
    setVerificationId('');
    setRequestStatus('in-progress');

    const res = await requestVerificationCode(`+90${phone.replace(/\D/g, '')}`);

    if (res.id) {
      setVerificationId(res.id);
      setRequestStatus('success');
    } else {
      setErrorMsg(res.msg);
      setRequestStatus('failure');
    }
  };

  useEffect(() => {
    if (open) {
      // send getVerificationCode request
      setPhoneCode('');
      requestVerification(phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {getText('register_page.phone_verification.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {getText('register_page.phone_verification.subtitle')}
        </DialogContentText>
        {requestStatus !== 'idle' && (
          <Typography variant="overline">
            {requestStatus === 'success' &&
              `${getText('register_page.phone_verification.success')}: ${verificationId}`}
            {requestStatus === 'failure' && `${errorMsg}`}
            {requestStatus === 'in-progress' &&
              getText('register_page.phone_verification.in_progress')}
          </Typography>
        )}

        <InputMask
          mask="99999"
          value={phoneCode}
          onChange={(event) => setPhoneCode(event.target.value)}
          disabled={requestStatus !== 'success'}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              autoFocus
              id="name"
              label={getText('register_page.phone_verification.verification_code')}
              variant="outlined"
              fullWidth
            />
          )}
        </InputMask>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setPhoneCode('');
            requestVerification(phone);
          }}
          color="primary"
          disabled={requestStatus === 'in-progress'}
        >
          {getText('register_page.phone_verification.send_again')}
        </Button>
        <Button onClick={onClose} color="primary">
          {getText('register_page.phone_verification.cancel')}
        </Button>
        <Button
          onClick={() => {
            submit(phoneCode, verificationId);
            onClose();
          }}
          color="primary"
          variant="contained"
          disabled={!phoneCode || phoneCode.length < 5 || requestStatus === 'failure'}
        >
          {getText('register_page.phone_verification.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PhoneVerification;
