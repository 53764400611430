import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { v4 } from 'uuid';

import Progress from '../components/Progress';

import Register from '../containers/register';

const query = (query) => {
  return new URLSearchParams(query);
};

const removeTxIdFromURL = (url) => {
  const urlObj = new URL(url);
  let params = new URLSearchParams(urlObj.search);

  params.delete('tx');
  return `${urlObj.origin}${urlObj.pathname}${params.toString() ? '?' + params.toString() : ''}`;
};

const Routes = () => {
  const { initialized } = useKeycloak();

  const location = useLocation();

  useEffect(() => {
    const txIdParam = query(location.search).get('tx');

    if (txIdParam) {
      sessionStorage.setItem('token_tx_id', txIdParam);
      console.log('got new tx id from query param', txIdParam);
      window.location.href = removeTxIdFromURL(window.location.href);
    } else {
      if (!sessionStorage.getItem('token_tx_id')) {
        const newTx = v4();
        sessionStorage.setItem('token_tx_id', newTx);
        console.log('set new tx id', newTx);
      } else {
        console.log('already have one', sessionStorage.getItem('token_tx_id'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialized) return <Progress />;

  return (
    <Switch>
      <Route exact path="/">
        <Register />
      </Route>
      <Route path="*">
        <Redirect
          to={{
            pathname: '/',
            search: location.search,
          }}
        />
      </Route>
    </Switch>
  );
};

export default Routes;
