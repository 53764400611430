import axios from '../lib/axios';

/** Register merchant */
export async function registerMerchant(data) {
  try {
    await axios.post('/api/identity/v1/user/register', {
      ...data,
      transactionId: sessionStorage.getItem('token_tx_id'),
    });

    return { success: true };
  } catch (error) {
    console.log(error.response);
    return { success: false };
  }
}
