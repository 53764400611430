import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationTr from './translations/tr-translation.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    // lng: "tr",
    fallbackLng: 'tr', // use tr if detected lng is not available

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    nonExplicitSupportedLngs: true,
    resources: {
      tr: {
        translations: translationTr,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
