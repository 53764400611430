import React from 'react';
import { I18nextProvider } from 'react-i18next';
// import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import i18n from './locale/i18n';
// import store from './store';

import Routes from './routes';
// import Layout from './components/layout';

import keycloak from './keycloak';

import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0076a9',
    },
  },
});

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      }}
    >
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<p>Loading...</p>}>
          <I18nextProvider i18n={i18n}>
            {/* <Provider store={store}> */}
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
            {/* </Provider> */}
          </I18nextProvider>
        </React.Suspense>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
