import axios from 'axios';
import keycloak from '../keycloak';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    if (keycloak.token)
      config.headers = { ...config.headers, Authorization: `Bearer ${keycloak.token || ''}` };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
