import axios from '../lib/axios';
import emailValidator from '../lib/emailValidator';

/** Validate email */
export async function validateEmail(email) {
  if (!emailValidator(email)) return false;

  try {
    const res = await axios.get(
      `/api/merchant-service/merchants/validate?authorized_person_email=${email}`,
    );
    if (res && res.data.code === '00000') return res.data.result;
    return {
      isValid: false,
      isExists: false,
    };
  } catch (error) {
    console.log(error.response);
    return {
      isValid: false,
      isExists: false,
    };
  }
}

/** Validate gsm */
export async function validatePhone(phone) {
  const sanitizedPhone = phone.replace(/\D/g, '');
  if (sanitizedPhone.length < 10) return false;

  try {
    const res = await axios.get(
      `/api/merchant-service/merchants/validate?authorized_person_gsm=90${sanitizedPhone}`,
    );
    if (res && res.data.code === '00000') return res.data.result;
    return {
      isValid: false,
      isExists: false,
    };
  } catch (error) {
    console.log(error.response);
    return {
      isValid: false,
      isExists: false,
    };
  }
}
