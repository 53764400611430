import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import isEmail from 'validator/es/lib/isEmail';
import { withRouter } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { green } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import TokenLogo from '../../assets/img/TokenLogo.svg';

import Snackbar from '../../components/Snackbar';
import PhoneVerification from '../../components/PhoneVerification';
import SuccessDialog from '../../components/SuccessDialog';
import TextInput from '../../components/TextInput';
import validatePassword from '../../lib/validatePassword';

import { registerMerchant } from '../../api/registration';
import { validateEmail, validatePhone } from '../../api/validation';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '16px auto',
  },
  imgContainer: {
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerImg: {
    maxWidth: 222,
  },
  paddingDesktop: {
    padding: '24px 64px',
    '& > *': {
      margin: '8px 0',
    },
  },
  paddingMobile: {
    padding: 16,
    '& > *': {
      margin: '8px 0',
    },
  },
}));

const ValidityIndicator = ({ isValid, label }) => (
  <Typography component="p" variant="caption">
    {isValid ? (
      <CheckIcon fontSize="small" style={{ color: green[500], verticalAlign: 'bottom' }} />
    ) : (
      <ClearIcon fontSize="small" color="secondary" style={{ verticalAlign: 'bottom' }} />
    )}{' '}
    {label}
  </Typography>
);

const Register = ({ location }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { keycloak } = useKeycloak();

  const { t: getText } = useTranslation();

  const initFields = {
    name: '',
    surname: '',
    email: '',
    gsm: '',
    password: '',
    kvkk_approval: false,
  };

  const [showPassword, togglePassword] = useState(false);
  const [values, setValues] = useState(initFields);
  const [loginUrls, setLoginUrls] = useState({ successPopup: '', loginButton: '' });
  const [passwordValidity, setPasswordValidity] = useState({});
  const [emailValidity, setEmailValidity] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(getText('errors.enter_valid_email'));
  const [phoneValidity, setPhoneValidity] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState(getText('errors.enter_valid_gsm'));
  const [passwordCapslock, setPasswordCapslock] = useState(false);
  const [phoneVerificationDialog, togglePhoneVerificationDialog] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('idle');

  const emailValidator = async (email) => {
    const res = await validateEmail(email);
    setEmailValidity(res.isValid && !res.isExists);
    setEmailHelperText(
      res.isExists ? getText('errors.existing_email') : getText('errors.enter_valid_email'),
    );
    return res.isValid && !res.isExists;
  };

  const phoneValidator = async (phone) => {
    const res = await validatePhone(phone);
    setPhoneValidity(res.isValid && !res.isExists);
    setPhoneHelperText(
      res.isExists ? getText('errors.existing_gsm') : getText('errors.enter_valid_gsm'),
    );
    return res.isValid && !res.isExists;
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.name === 'email') {
      value = value?.toLowerCase();
    }
    setValues({ ...values, [event.target.name]: value });
  };

  const handleCheckUncheck = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const checkCapsLock = (event) => {
    const capsLockOn = event.getModifierState('CapsLock');

    if (capsLockOn) setPasswordCapslock(true);
    else setPasswordCapslock(false);
  };

  useEffect(() => {
    document.getElementsByName('password')[0].addEventListener('keyup', checkCapsLock);
    document.getElementsByName('password')[0].addEventListener('mousedown', checkCapsLock);
    document
      .getElementsByName('password')[0]
      .addEventListener('blur', () => setPasswordCapslock(false));

    return () => {
      document.getElementsByName('password')[0].removeEventListener('keyup', checkCapsLock);
      document.getElementsByName('password')[0].removeEventListener('mousedown', checkCapsLock);
      document
        .getElementsByName('password')[0]
        .removeEventListener('blur', () => setPasswordCapslock(false));
    };
  }, []);

  useEffect(() => {
    const redirectUri = new URLSearchParams(location.search).get('redirectUrl');

    if (!redirectUri) {
      setLoginUrls({
        successPopup: keycloak.createLoginUrl({
          redirectUri: `${process.env.REACT_APP_DIGITAL_STORE_URL}?tx=${sessionStorage.getItem(
            'token_tx_id',
          )}`,
        }),
        loginButton: keycloak.createLoginUrl({
          redirectUri: `${process.env.REACT_APP_MERCHANT_PANEL_URL}?tx=${sessionStorage.getItem(
            'token_tx_id',
          )}`,
        }),
      });
    } else {
      setLoginUrls({
        successPopup: keycloak.createLoginUrl({
          redirectUri: `${redirectUri}?tx=${sessionStorage.getItem('token_tx_id')}`,
        }),
        loginButton: keycloak.createLoginUrl({
          redirectUri: `${redirectUri}?tx=${sessionStorage.getItem('token_tx_id')}`,
        }),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPasswordValidity(validatePassword(values.password));
  }, [values.password]);

  const isSubmitDisabled =
    !values.name.trim() ||
    !values.surname.trim() ||
    !values.email.trim() ||
    !isEmail(values.email.trim()) ||
    !emailValidity ||
    !values.gsm ||
    values.gsm.replace(/\D/g, '').length !== 10 ||
    !phoneValidity ||
    !values.password ||
    !passwordValidity.overall ||
    !values.kvkk_approval ||
    submitStatus === 'in-progress' ||
    submitStatus === 'success';

  const submit = async (verificationCode, verificationId) => {
    togglePhoneVerificationDialog(false);

    setSubmitStatus('in-progress');

    const body = {
      ...values,
      name: values.name.trim(),
      surname: values.surname.trim(),
      email: values.email.trim(),
      verificationCode,
      verificationId: `${verificationId}`,
      gsm: `+90${values.gsm.replace(/\D/g, '')}`,
    };

    delete body.kvkk_approval;

    const res = await registerMerchant(body);

    if (res.success) {
      setSubmitStatus('success');
      setValues(initFields);
    } else setSubmitStatus('failure');
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper>
        <div className={classes.imgContainer}>
          <img src={TokenLogo} alt="Token Logo" className={classes.headerImg} />
        </div>
        <Divider />
        <form
          className={mobile ? classes.paddingMobile : classes.paddingDesktop}
          autoComplete="off"
        >
          <Typography align="center" variant={mobile ? 'h5' : 'h4'}>
            {getText('register_page.title')}
          </Typography>
          {/* <Typography variant="body2">{getText('register_page.subtitle')}</Typography> */}
          <TextInput
            name="name"
            variant="outlined"
            label={getText('register_page.labels.name')}
            onChange={handleChange}
            value={values.name}
            inputProps={{ maxLength: 40 }}
            disabled={submitStatus === 'in-progress' || submitStatus === 'success'}
            {...(mobile ? { size: 'small' } : {})}
            fullWidth
          />
          <TextInput
            name="surname"
            variant="outlined"
            label={getText('register_page.labels.surname')}
            onChange={handleChange}
            value={values.surname}
            inputProps={{ maxLength: 40 }}
            disabled={submitStatus === 'in-progress' || submitStatus === 'success'}
            {...(mobile ? { size: 'small' } : {})}
            fullWidth
          />
          <TextInput
            name="email"
            variant="outlined"
            label={getText('register_page.labels.email')}
            onChange={handleChange}
            value={values.email}
            {...(values.email && !isEmail(values.email)
              ? { helperText: getText('errors.enter_valid_email'), error: true }
              : {})}
            inputProps={{ maxLength: 80, style: { textTransform: 'lowercase' } }}
            type="email"
            disabled={submitStatus === 'in-progress' || submitStatus === 'success'}
            validator={emailValidator}
            errorHelperText={emailHelperText}
            {...(mobile ? { size: 'small' } : {})}
            fullWidth
          />
          <InputMask
            mask="(999) 999 99 99"
            value={values.gsm}
            onChange={handleChange}
            validator={phoneValidator}
            disabled={submitStatus === 'in-progress' || submitStatus === 'success'}
          >
            {(inputProps) => (
              <TextInput
                {...inputProps}
                name="gsm"
                variant="outlined"
                label={getText('register_page.labels.phone')}
                type="tel"
                errorHelperText={getText('errors.enter_valid_gsm')}
                fullWidth
              />
            )}
          </InputMask>
          <TextInput
            name="password"
            variant="outlined"
            label={getText('register_page.labels.password')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      showPassword
                        ? getText('register_page.labels.hide_password')
                        : getText('register_page.labels.show_password')
                    }
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePassword(!showPassword)}
                      disabled={submitStatus === 'in-progress' || submitStatus === 'success'}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            value={values.password}
            disabled={submitStatus === 'in-progress' || submitStatus === 'success'}
            {...(mobile ? { size: 'small' } : {})}
            {...(passwordCapslock
              ? { helperText: getText('register_page.labels.caps_lock_on') }
              : {})}
            fullWidth
          />
          <div>
            <Typography component="p" variant="caption">
              {getText('password_validation.title')}
            </Typography>
            <ValidityIndicator
              label={getText('password_validation.length')}
              isValid={passwordValidity.length}
            />
            <ValidityIndicator
              label={getText('password_validation.lowercase')}
              isValid={passwordValidity.lowercase}
            />
            <ValidityIndicator
              label={getText('password_validation.uppercase')}
              isValid={passwordValidity.uppercase}
            />
            <ValidityIndicator
              label={getText('password_validation.number')}
              isValid={passwordValidity.number}
            />
            <ValidityIndicator
              label={getText('password_validation.symbol')}
              isValid={passwordValidity.symbol}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                name="kvkk_approval"
                color="primary"
                onChange={handleCheckUncheck}
                checked={values.kvkk_approval}
                disabled={submitStatus === 'in-progress' || submitStatus === 'success'}
              />
            }
            label={
              <Typography>
                <Link
                  href="https://www.tokeninc.com/getmedia/8420ac0c-d8fd-421e-ac01-f2b8c6a8d4c2/23577-TOKEN_KISISEL_VERILERIN_KORUNMASI_HAKKINDA_ACIKLAMA_VE_GIZLILIK_POLITIKASI_NISAN-2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getText('register_page.labels.kvkk_link')}
                </Link>{' '}
                {getText('register_page.labels.kvkk_approval')}
              </Typography>
            }
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disabled={isSubmitDisabled}
            onClick={() => togglePhoneVerificationDialog(true)}
            fullWidth
          >
            {getText('register_page.labels.submit')}
          </Button>
          <Typography variant="body1">
            {getText('register_page.already_has_account')}{' '}
            <Button color="primary" component="a" href={loginUrls.loginButton}>
              {getText('register_page.redirect_login')}
            </Button>
          </Typography>
        </form>
      </Paper>
      <PhoneVerification
        open={phoneVerificationDialog}
        onClose={() => togglePhoneVerificationDialog(false)}
        phone={values.gsm}
        submit={submit}
      />
      <Snackbar
        open={submitStatus === 'in-progress'}
        message={getText('register_page.submit.in_progress')}
        type="info"
      />
      <SuccessDialog
        open={submitStatus === 'success'}
        onClose={() => (window.location.href = loginUrls.successPopup)}
        buttonLabel={getText('register_page.submit.continue')}
      >
        <Typography variant="h4">{getText('register_page.submit.success_title')}</Typography>
        <Typography variant="body1">{getText('register_page.submit.success_subtitle')}</Typography>
      </SuccessDialog>
      <Snackbar
        open={submitStatus === 'failure'}
        onClose={() => setSubmitStatus('idle')}
        message={getText('register_page.submit.failure')}
        type="error"
      />
    </Container>
  );
};

export default withRouter(Register);
