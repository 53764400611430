import React, { useState, useRef } from 'react';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import debounce from 'lodash.debounce';

const TextInput = (props) => {
  const [validity, setValidity] = useState(null);

  const asyncValidate = useRef(
    debounce(async (value) => {
      const validation = await props.validator(value);
      setValidity(validation ? 'valid' : 'invalid');
    }, 500),
  ).current;

  const handleChange = (event) => {
    props.onChange(event);

    if (props.validator) {
      setValidity('checking');
      asyncValidate(event.target.value);
    }
  };

  return (
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        label={props.label}
        fullWidth
        {...props}
        InputProps={{
          ...(props.validator
            ? {
                endAdornment: (
                  <React.Fragment>
                    {validity === 'checking' && <CircularProgress color="inherit" size={20} />}
                    {validity === 'valid' && (
                      <CheckCircleOutlinedIcon style={{ color: green[500] }} size={20} />
                    )}
                    {validity === 'invalid' && <CancelOutlinedIcon color="secondary" size={20} />}
                  </React.Fragment>
                ),
              }
            : {}),
          onChange: handleChange,
          ...props.InputProps,
        }}
        {...(props.validator && validity === 'invalid'
          ? {
              helperText: props.errorHelperText || 'Hatalı giriş',
              FormHelperTextProps: { error: true },
              error: true,
            }
          : {})}
      />
    </Grid>
  );
};

export default TextInput;
