import axios from '../lib/axios';

/** Request Verification Code (to merchants phone) */
export async function requestVerificationCode(phone) {
  try {
    const res = await axios.post('/api/merchant-service/authorization', {
      gsm: phone,
      type: 'gsm_verification',
    });
    let verificationCode = {};

    if (res.data && res.data.code === '000000') verificationCode = res.data.result;

    return verificationCode;
  } catch (error) {
    let msg;

    switch (error.response.data.code) {
      case 5001:
      case '5001':
        msg = 'Girilen numara ile daha önce işlem yapılmıştır, lütfen başka bir numara giriniz.';
        break;
      case 5002:
      case '5002':
        msg = 'İşlem zamanaşımına uğradı, lütfen tekrar deneyin.';
        break;
      case 5004:
      case '5004':
        msg = 'Hatalı doğrulama kodu girişi yaptınız, lütfen tekrar deneyin.';
        break;
      case 5005:
      case '5005':
        msg = 'SMS gönderilemedi, "Tekrar Gönder" butonunu kullanarak yeni bir kod oluşturun.';
        break;
      default:
        msg = 'Bir Hata Oluştu!';
    }

    return { error: error.response.data.code, msg };
  }
}
